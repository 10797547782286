//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			value1: '',
			input1: '',
			input2: '',
			textarea: ''
		}
	},
	methods:{
		fanhui(){
			this.$router.go(-1)
		}
	},
}
